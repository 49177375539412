import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { IButtonStyles, IconButton, IPanelStyles, IScrollablePaneStyles, ITextFieldStyles, Label, Panel, PanelType, ScrollablePane, TextField, useTheme } from '@fluentui/react';
import { useStoreState, useStoreActions } from '../store/Store';
import { DocumentViewer } from '../components/DocumentViewer';
import { getFromApi } from '../helper/ApiHelper';
import { ILetter } from '../types/ILetter';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { formatToGermanDate } from '../helper/DateFormatHelper';
import { formatLetterTypeToText } from '../helper/LetterHelper';
import { useTranslation } from 'react-i18next';
import { formatBytes } from '../helper/ByteHelper';
import { IThemed } from '../types/IThemed';

const BodyWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin-top: 31px;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: end;
    padding: 20px;
`;

const LetterContentContainer = styled.div<IThemed>`
    display: flex;
    flex: 1;
    position: relative;
    border: 1px solid ${(props) => props.palette.neutralQuaternaryAlt};
    margin: 0px 20px;
    border-radius: 2px;
`;

const RowWrapper = styled.div`
    display: flex;
    padding: 0px 20px 31px 20px;
    gap: 15px;
`;

/**
 * The interface for the LetterViewPanel
 */
interface ILetterViewPanel {
    /** The selected letter to display. */
    selectedLetter: ILetter | undefined;
    /** Method to update the letter status of the selected letter. */
    updateLetterStatus?: () => void;
}

/**
 * Panel component to display a single letter.
 *
 * @param {ILetterViewPanel} props The properties of the letter view panel.
 * @returns {FunctionComponent} The letters view panel component.
 */
export const LetterViewPanel: FunctionComponent<ILetterViewPanel> = (props) => {
    /** Access to the fluent ui theme. */
    const theme = useTheme();
    /** Access to translations. */
    const { t } = useTranslation();
    /** Whether this component is busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** The sas token for the letter. */
    const [sasToken, setSaSToken] = useState<string>();
    /** Whether the panel to view the original file is open or not. */
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    /** Store state of the EditUserPanel. */
    const isLetterViewerPanelOpen = useStoreState((state) => state.LetterViewerModel.isOpen);
    /** Action to update the state oft the EditUserPanel. */
    const updateIsLetterViewerPanelOpen = useStoreActions((actions) => actions.LetterViewerModel.updateIsOpen);

    /** Reset the sas token when the letter changes */
    useEffect(() => {
        setSaSToken(undefined);
    }, [props.selectedLetter?.id]);

    /** Updates the letter status to 'read' when closing the preview. */
    const handleClosePreview = () => {
        if (props.updateLetterStatus !== undefined) {
            props.updateLetterStatus();
        }
        updateIsLetterViewerPanelOpen(false);
    };

    /** The styles of the button that opens the attachment preview panel. */
    const attachmentButtonStyles: IButtonStyles = {
        root: {
            borderRadius: 9999,
            padding: 20,
            color: theme.palette.black,
        },
        icon: {
            fontSize: 20,
        },
    };

    /** The styles for the scrollable content area. */
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
        contentContainer: {
            paddingRight: 8,
            paddingLeft: 8,
        },
    };

    /** The general text field styles to apply. */
    const marginTextFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            flex: 1,
        },
    };

    /**
     * Render function of the panel body.
     *
     * @returns {Element} The panel body component.
     */
    const onRenderBody = () => (
        <BodyWrapper>
            <BusySpinnerOverlay isBusy={isBusy} />
            <RowWrapper>
                <TextField styles={marginTextFieldStyles} label={t('LetterTable_sender')} readOnly value={props.selectedLetter?.sender} />
                <TextField
                    label={t('LetterTable_letterType')}
                    readOnly
                    value={props.selectedLetter?.letterType !== undefined ? formatLetterTypeToText(props.selectedLetter.letterType, t) : ''}
                />
                <TextField label={t('LetterViewPanel_LetterDate')} readOnly value={props.selectedLetter?.letterDate ? formatToGermanDate(props.selectedLetter?.letterDate) : ''} />
            </RowWrapper>
            <RowWrapper>
                <TextField styles={marginTextFieldStyles} label={t('LetterTable_originalFileName')} readOnly value={props.selectedLetter?.originalFilename} />
                <TextField label={t('LetterTable_fileSize')} readOnly value={props.selectedLetter?.size ? formatBytes(props.selectedLetter.size, 2) : ''} />
            </RowWrapper>
            <Label styles={{ root: { padding: '0 20px', marginBottom: 5 } }}>{t('LetterViewPanel_LetterContent')}</Label>
            <LetterContentContainer palette={theme.palette}>
                <ScrollablePane styles={scrollablePaneStyles} scrollContainerFocus={true}>
                    <p>
                        <b>{props.selectedLetter?.firstLine}</b>
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: props.selectedLetter?.content?.replace(/\n/g, '<br>') ?? '' }} />
                </ScrollablePane>
            </LetterContentContainer>
        </BodyWrapper>
    );

    /**
     * Render function of the panel footer.
     *
     * @returns {Element} The panel footer component.
     */
    const onRenderFooter = () => (
        <FooterContainer>
            <IconButton
                styles={attachmentButtonStyles}
                iconProps={{ iconName: 'Attach' }}
                onClick={async () => {
                    if (!sasToken && props.selectedLetter) {
                        try {
                            setIsBusy(true);
                            if (!props.selectedLetter) {
                                return;
                            }
                            setSaSToken(await getFromApi(`Letter/GenerateDownloadToken/${props.selectedLetter.id}.pdf`));
                            setIsPreviewOpen(true);
                        } catch (error) {
                            console.error(error);
                        } finally {
                            setIsBusy(false);
                        }
                        return;
                    }
                    setIsPreviewOpen(true);
                }}
            />
        </FooterContainer>
    );

    /**
     * Render function of the preview panel body.
     *
     * @returns {Element} The body of the file preview panel.
     */
    const onRenderPreviewBody = () => (
        <BodyWrapper>
            <DocumentViewer sasToken={sasToken} fileName={props.selectedLetter?.originalFilename} />
        </BodyWrapper>
    );

    /**
     * Panel style for an centered panel with a margin for larger devices.
     */
    const centeredPanel: Partial<IPanelStyles> = {
        main: {
            width: 'unset',
            '@media(min-width:480px)': {
                margin: '5%',
            },
        },
    };

    return (
        <>
            <Panel
                styles={centeredPanel}
                allowTouchBodyScroll
                isOpen={isLetterViewerPanelOpen && props.selectedLetter !== undefined}
                isBlocking
                isLightDismiss
                headerText={props.selectedLetter?.label}
                type={PanelType.smallFluid}
                onDismiss={() => handleClosePreview()}
                onRenderBody={onRenderBody}
                isFooterAtBottom
                onRenderFooter={onRenderFooter}
            />
            <Panel
                allowTouchBodyScroll
                isOpen={isPreviewOpen}
                isBlocking
                isLightDismiss
                type={PanelType.custom}
                onDismiss={() => setIsPreviewOpen(false)}
                onRenderBody={onRenderPreviewBody}
            />
        </>
    );
};
