import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { ITextFieldStyles, DefaultButton, PrimaryButton, Panel, PanelType, MessageBarType, Stack, IStackTokens, Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react';
import { useStoreState, useStoreActions } from '../store/Store';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { formatToGermanDate } from '../helper/DateFormatHelper';
import { IUser } from '../types/IUser';
import { IRole } from '../types/IRole';
import { IUserRole } from '../types/IUserRole';
import { getFromApi, HttpError, postToApi, putToApi } from '../helper/ApiHelper';
import { IInviteRole } from '../types/IInviteRole';
import { validateEmail } from '../helper/EmailHelper';
import { IInvite } from '../types/IInvite';
import { ILetter } from '../types/ILetter';
import { ILetterInviteUpdate } from '../types/ILetterInviteUpdate';
import { ILetterInvite } from '../types/ILetterInvite';
import { useQueryClient } from '@tanstack/react-query';

const PanelHeader = styled.div`
    padding: 0 0 20px 20px;
    width: 100%;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
`;

const StackContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

/**
 * Panel component to display a single employee for edit or new creation.
 *
 * @returns {FunctionComponent} EditUser Panel.
 */
export const EditUserPanel: FunctionComponent = () => {
    /** Access to translations. */
    const { t } = useTranslation();
    /** Access to the query client. */
    const queryClient = useQueryClient();

    /** Store state of the EditUserPanel. */
    const panelPurpose = useStoreState((state) => state.UsersModel.panelPurpose);
    /** Global state representing if assigned or collection letters are being shown. */
    const showType = useStoreState((state) => state.LettersModel.showType);
    /** Globally available roles. */
    const roles = useStoreState((state) => state.RolesModel.roles);
    /** Action to fetch the letters. */
    const fetchLetters = useStoreActions((actions) => actions.LettersModel.fetchLetters);
    /** Globally fetches all available roles. */
    const fetchRoles = useStoreActions((actions) => actions.RolesModel.fetchRoles);
    /** Action to update the state oft the EditUserPanel. */
    const updatePanelPurpose = useStoreActions((actions) => actions.UsersModel.updatePanelPurpose);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /** The original (not manipulated) entity. */
    const [originalUser, setOriginalUser] = useState<IUser>();
    /** The date of the last login of the user. */
    const [lastLogin, setLastLoginDate] = useState<Date | undefined>(undefined);
    /** Whether this component is busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** The first name of the user. */
    const [displayName, setDisplayName] = useState<string | undefined>('');
    /** The user's role. */
    const [userRole, setUserRole] = useState<IRole>();
    /** The email address of the user. */
    const [userEmail, setUserEmail] = useState<string | undefined>('');

    /** Indicates if roles have been loaded. */
    const dependenciesLoaded = roles.length > 0;
    /** Checks the validity of the input. */
    const isValid = displayName !== undefined && displayName !== '' && displayName !== ' ' && userRole !== undefined && userRole?.id !== 0;
    /** Checks if changes were made. */
    const isChanged =
        displayName !== originalUser?.displayName || userRole?.id !== (originalUser?.userRoles && originalUser?.userRoles.length > 0 ? originalUser?.userRoles[0].roleId : 0);
    /** Group of local constants to access panel purpose */
    const isUpdate = panelPurpose.mode === 'UPDATE';
    const isCreate = panelPurpose.mode === 'CREATE';

    /**
     * Initialize states.
     */
    useEffect(() => {
        const getCurrentState = async () => {
            if (panelPurpose.isOpen) {
                if (!dependenciesLoaded) {
                    await fetchRoles();
                }
                if (panelPurpose.entityId) {
                    const response = await getFromApi<IUser>(`User/${panelPurpose.entityId}`);
                    if (response) {
                        setOriginalUser(response);
                        setDisplayName(response.displayName);
                        setLastLoginDate(response.lastLogin);
                        setUserEmail(response.email);
                        setUserRole(roles.find((r) => r.id === (response.userRoles && response.userRoles.length > 0 ? response.userRoles[0].roleId : 0)));
                    }
                }
            }
        };
        getCurrentState();
    }, [roles, panelPurpose.entityId, panelPurpose.isOpen, fetchRoles, dependenciesLoaded]);

    /**
     * Resets the component state.
     */
    const resetState = useCallback((): void => {
        updatePanelPurpose({ isOpen: false, mode: 'CREATE' });
        setOriginalUser(undefined);
        setDisplayName('');
        setLastLoginDate(undefined);
        setUserEmail('');
        setUserRole(undefined);
        queryClient.invalidateQueries(['entity-user']);
    }, [queryClient, updatePanelPurpose, setOriginalUser, setDisplayName, setLastLoginDate, setUserEmail, setUserRole]);

    /** Stack tokens for elements making up multiple rows. */
    const rowStackTokens: IStackTokens = {
        childrenGap: 35,
    };

    /** Stack tokens for elements inside a row. */
    const columnStackTokens: IStackTokens = {
        childrenGap: 31,
    };

    /** Styles for the employee textfield. */
    const textFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            width: '100%',
        },
    };

    /** Dropdown styles. */
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            width: '100%',
        },
        dropdownItemsWrapper: {
            maxHeight: 450,
        },
    };

    /**
     * Transforms ILetter[] into ILetterInviteUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {number} inviteId The invite id to transform with.
     * @returns {ILetterInviteUpdate[]} The transformed elements.
     */
    const transformLettersForInvite = (letters: ILetter[], inviteId: number): ILetterInviteUpdate[] => {
        const letterDenyUpdates: ILetterInviteUpdate[] = [];
        letters.forEach((letter) => {
            letterDenyUpdates.push({
                id: letter.id,
                inviteId: inviteId,
            });
        });
        return letterDenyUpdates;
    };

    /**
     * Callback to create a new invite in the backend.
     */
    const createInvite = async () => {
        setIsBusy(true);
        try {
            const newInviteRole: IInviteRole = { id: 0, roleId: userRole?.id ?? 1, inviteId: 0 };
            if (userEmail) {
                const newInvite: ILetterInvite = {
                    email: userEmail,
                    inviteRoles: [newInviteRole],
                    letterInvitees: panelPurpose.letters ? transformLettersForInvite(panelPurpose.letters, 0) : undefined,
                };
                await postToApi<IInvite>('User/Invite', newInvite);
                updateNotification({ message: t('User_Creation_Success'), type: MessageBarType.success });
                resetState();
                if (panelPurpose.letters) {
                    fetchLetters(showType);
                }
            }
        } catch (error) {
            console.error(error);
            updateNotification({ message: t('User_Creation_Error'), type: MessageBarType.error });
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Callback to update a User in the backend.
     */
    const updateExistingUser = async () => {
        setIsBusy(true);
        try {
            const userRoleId = originalUser?.userRoles && originalUser?.userRoles.length > 0 ? originalUser?.userRoles[0].id : 0;
            const objectId = originalUser?.objectId;
            if (!objectId) {
                throw new Error('No object id on user.');
            }
            const newUserRole: IUserRole = { id: userRoleId, roleId: userRole?.id ?? 1, userId: panelPurpose.entityId ?? 0 };
            const newUser: IUser = {
                id: panelPurpose.entityId ?? 0,
                email: userEmail,
                displayName: displayName,
                lastLogin: lastLogin,
                objectId: objectId,
            };
            // Update the user profile.
            await postToApi('User/Update', newUser);
            // Update the user role.
            await putToApi('User/roles', [newUserRole]);
            updateNotification({ message: t('User_Update_Success'), type: MessageBarType.success });
            resetState();
        } catch (error) {
            console.error(error);
            const httpError = error as HttpError;
            resetState();
            if (httpError.status === 409) {
                updateNotification({ message: t('User_Update_UserRepresentationError'), type: MessageBarType.error });
            } else {
                updateNotification({ message: t('User_Update_Error'), type: MessageBarType.error });
            }
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Header of the panel.
     *
     * @returns {Element} The header element.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <Text variant={'large'}>{isCreate ? t('InviteUserPanel_Header') : t('EditUserPanel_Header')}</Text>
        </PanelHeader>
    );

    /**
     * Render the recipients as a string.
     *
     * @returns {string} The string.
     */
    const renderLetterRecipients = (): string => {
        const recipients = panelPurpose.letters?.map((l) => l.recipient).join(', ') ?? '';
        return recipients;
    };

    /**
     * The body of the panel.
     *
     * @returns {HTMLElement} The body.
     */
    const onRenderBody = () => (
        <BodyWrapper>
            <BusySpinnerOverlay isBusy={isBusy} />
            <StackContainer>
                <Stack tokens={rowStackTokens}>
                    <Stack horizontal tokens={columnStackTokens}>
                        {!isCreate ? (
                            <TextField
                                styles={textFieldStyles}
                                value={displayName ?? ''}
                                label={t('EditUserPanel_Textfield_Name')}
                                placeholder={t('EditUserPanel_Textfield_Name')}
                                onChange={(_, newValue?: string) => {
                                    if (newValue) {
                                        setDisplayName(newValue);
                                    } else {
                                        setDisplayName(undefined);
                                    }
                                }}
                                validateOnLoad={false}
                                validateOnFocusOut={true}
                            />
                        ) : (
                            panelPurpose.letters && <TextField readOnly value={renderLetterRecipients()} label={t('EditRecipientsPanel_TextField_Recipient')} />
                        )}
                    </Stack>
                    <Stack horizontal tokens={columnStackTokens}>
                        <Dropdown
                            label={t('EditUserPanel_Textfield_UserRole')}
                            placeholder={t('EditUserPanel_Textfield_UserRole')}
                            options={roles.map((r) => {
                                return { key: r.id, text: r.label };
                            })}
                            selectedKey={userRole?.id}
                            styles={dropdownStyles}
                            onChange={(_, option?: IDropdownOption | undefined): void => {
                                if (option) {
                                    setUserRole({ id: Number(option.key), label: option.text });
                                }
                            }}
                        />
                    </Stack>
                    <Stack horizontal tokens={columnStackTokens}>
                        <TextField
                            readOnly={isCreate ? false : true}
                            styles={textFieldStyles}
                            value={userEmail}
                            placeholder={t('EditUserPanel_Textfield_Email')}
                            label={t('EditUserPanel_Textfield_Email')}
                            onChange={(_, newValue?: string) => {
                                if (newValue) {
                                    setUserEmail(newValue);
                                } else {
                                    setUserEmail(undefined);
                                }
                            }}
                        />
                    </Stack>
                    {!isCreate && (
                        <Stack horizontal tokens={columnStackTokens}>
                            <TextField
                                readOnly={true}
                                styles={textFieldStyles}
                                value={formatToGermanDate(lastLogin)}
                                label={t('EditUserPanel_Textfield_LastLoginDate')}
                                placeholder="-"
                            />
                        </Stack>
                    )}
                </Stack>
            </StackContainer>
        </BodyWrapper>
    );

    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton text={t('EditUserPanel_Footer_CancelButton')} onClick={() => resetState()} />
            <PrimaryButton
                disabled={(isUpdate && !(isValid && isChanged)) || (isCreate && !validateEmail(userEmail ?? ''))}
                text={isCreate ? t('InviteUserPanel_Footer_InviteButton') : t('EditUserPanel_Footer_ConfirmButton')}
                onClick={() => {
                    if (isUpdate) {
                        updateExistingUser();
                    } else if (isCreate) {
                        createInvite();
                        resetState();
                    }
                }}
            />
        </Stack>
    );
    return (
        <Panel
            allowTouchBodyScroll
            isOpen={panelPurpose.isOpen}
            isFooterAtBottom
            isBlocking
            isLightDismiss
            type={PanelType.medium}
            onRenderHeader={onRenderHeader}
            onDismiss={resetState}
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooter}
        />
    );
};
